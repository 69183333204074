export default function Instagram({ className = "w-5 fill-current" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 22"
      className={className}>
      <g>
        <path d="M10.87 5.478C7.852 5.478 5.35 7.938 5.35 11a5.505 5.505 0 005.522 5.522c3.062 0 5.521-2.502 5.521-5.522 0-3.02-2.502-5.522-5.521-5.522zm0 9.06A3.553 3.553 0 017.334 11a3.553 3.553 0 013.538-3.537A3.553 3.553 0 0114.408 11a3.553 3.553 0 01-3.537 3.537z" />
        <circle cx="16.608" cy="5.349" r="1.251" />
        <path d="M19.843 2.114C18.722.949 17.125.345 15.313.345H6.428C2.675.345.173 2.847.173 6.6v8.843c0 1.855.603 3.451 1.811 4.616C3.15 21.18 4.702 21.74 6.471 21.74h8.8c1.854 0 3.407-.604 4.529-1.682 1.165-1.122 1.769-2.718 1.769-4.573V6.6c0-1.812-.604-3.365-1.726-4.486zm-.172 13.372c0 1.338-.475 2.416-1.251 3.15-.777.733-1.855 1.12-3.15 1.12h-8.8c-1.294 0-2.372-.387-3.148-1.12-.777-.777-1.165-1.856-1.165-3.193V6.6c0-1.294.388-2.373 1.165-3.149.733-.733 1.854-1.122 3.149-1.122h8.886c1.294 0 2.372.389 3.149 1.165.733.777 1.165 1.855 1.165 3.106v8.886z" />
      </g>
    </svg>
  )
}
